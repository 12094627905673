import React from 'react';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';
import PartnerImg1 from '../../images/partners/partner1.png';
import PartnerImg2 from '../../images/partners/partner2.png';
import PartnerImg3 from '../../images/partners/partner3.png';
import PartnerImg4 from '../../images/partners/partner4.png';
import PartnerImg5 from '../../images/partners/partner5.png';
import PartnerImg6 from '../../images/partners/partner6.png';
import PartnerImg7 from '../../images/partners/partner7.png';
import PartnerImg8 from '../../images/partners/sakbolkamsyzdan.png';
import './styles.scss';

const Partners = () => {
	const { t } = useTranslation();

	const partners = [
		{
			img: PartnerImg1,
			id: 'partner1',
			href: 'https://www.kraken.com/',
			alt: 'Kraken',
		},
		{
			img: PartnerImg2,
			id: 'partner2',
			href: 'https://rpg.kg/',
			alt: 'Royal Pure Gold',
		},
		{
			img: PartnerImg3,
			id: 'partner3',
			href: 'https://onealpha.io/',
			alt: 'OneAlpha',
		},
		{
			img: PartnerImg4,
			id: 'partner4',
			href: 'https://www.cbk.kg/',
			alt: 'Commercial Bank of Kyrgyzstan',
		},
		{
			img: PartnerImg5,
			id: 'partner5',
			href: 'https://omegacapital.kg/',
			alt: 'Omega Capital',
		},
		{
			img: PartnerImg6,
			id: 'partner6',
			href: 'https://kcsd.kz/',
			alt: 'Kazakhstan Central Securities Depository',
		},
		{
			img: PartnerImg7,
			id: 'partner7',
			href: 'https://sumsub.com/',
			alt: 'Sumsub',
		},
		{
			img: PartnerImg8,
			id: 'partner8',
			href: 'http://sakbol.kg/',
			alt: 'Sak Bol Insurance',
		},
	];

	return (
		<>
			<section className='partners'>
				<div className='container'>
					<h1 className='partners__title title'>{t('partners.title')}</h1>
					<div className='partners__content'>
						{partners.map((partner, index) => {
							return (
								<motion.div
									className='partners__col'
									key={index}
									initial={{ scale: 0 }}
									whileInView={{ scale: 1 }}
									viewport={{ once: true }}
									transition={{ duration: 0.5, delay: index * 0.1 }}
								>
									<a
										href={partner.href}
										target='_blank'
										rel='noopener noreferrer'
										className='partners__link'
									>
										<div
											className={`partners__img-block partners__img-block--${partner.id}`}
										>
											<img
												src={partner.img}
												className='partners__img'
												alt={partner.alt}
											/>
										</div>
									</a>
								</motion.div>
							);
						})}
					</div>
				</div>
			</section>
		</>
	);
};

export default Partners;
