import React from 'react';
import { useTranslation } from 'react-i18next';
import Video from '../../images/hero/cover.mp4';
import { motion } from 'framer-motion';
import './styles.scss';

const Hero = () => {
	const { t, i18n } = useTranslation();
	const currentLanguage = i18n.language;

	return (
		<section className='hero'>
			<div className='hero__video-block'>
				<video
					autoPlay={true}
					loop={true}
					playsInline={true}
					muted={true}
					className='hero__video'
				>
					<source src={Video} type='video/mp4' />
				</video>
			</div>
			<div className='hero__info'>
				<motion.h1
					initial={{ opacity: 0, y: '-60px' }}
					animate={{ opacity: 1, y: 0 }}
					transition={{ duration: 0.5, delay: 0.2 }}
					className='hero__title'
				>
					{t('hero.title')}
				</motion.h1>
				<motion.p
					initial={{ opacity: 0, y: '-60px' }}
					animate={{ opacity: 1, y: 0 }}
					transition={{ duration: 0.5, delay: 0.3 }}
					className='hero__text'
				>
					{t('hero.description')}
				</motion.p>
				<div
					className='hero__presentation_block'
				>
					<motion.a
						href={
							currentLanguage === 'en'
								? 'https://drive.google.com/file/d/17_iE4F9wriWruw5Yy3MD9Qvo6verB5xU/view?usp=sharing'
								: 'https://drive.google.com/file/d/1KKGtSQtYqfJ0Tb1zfoWlD0hf5NJlN73q/view?usp=sharing'
						}
						className='hero__presentation'
						target='_blank'
						rel='noopener noreferrer'
						initial={{ opacity: 0, scale: 0.8 }}
						animate={{ opacity: 1, scale: 1 }}
						transition={{ duration: 0.3 }}
						whileHover={{ scale: 1.05 }}
						whileTap={{ scale: 0.95 }}
					>
						{t('hero.presentation')}
					</motion.a>
				</div>
			</div>
		</section>
	);
};

export default Hero;
