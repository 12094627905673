import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import './styles.scss';

import telegramIcon from '../../images/telegram-icon.svg';
import instagramIcon from '../../images/instagram-icon.svg';
import twitterIcon from '../../images/x-icon.svg';
import tiktokIcon from '../../images/tiktok-icon.svg';
import { Link, useLocation } from 'react-router-dom';

const Footer = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const [prevLocation, setPrevLocation] = useState(null);

  const socialLinks = [
    {
      name: 'Telegram',
      icon: telegramIcon,
      href: 'https://t.me/envoys_vision',
    },
    {
      name: 'Instagram',
      icon: instagramIcon,
      href: 'https://www.instagram.com/envoys.vision/',
    },
    {
      name: 'Twitter',
      icon: twitterIcon,
      href: 'https://twitter.com/envoysvisionde',
    },
    {
      name: 'TikTok',
      icon: tiktokIcon,
      href: 'https://www.tiktok.com/@envoys.vision',
    },
  ];

  const footerSections = [
    {
      title: t('footer.about.title'),
      links: [
        { text: t('footer.about.links.about'), url: '/documents' },
        { text: t('footer.about.links.financialReports'), url: '/statement' },
        { text: t('footer.about.links.news'), url: '/exchange-news' },
        { text: t('footer.about.links.confidentiality'), url: '/confidentiality' },
        { text: t('footer.about.links.riskDisclosure'), url: '/disclosure' },
        {
          text: t('footer.about.links.privacyPolicy'),
          url: 'https://drive.google.com/file/d/1AkZotlvnMZ_ppycbhAhJ500qh1Jxwy5p/view?usp=sharing',
        },
        {
          text: t('footer.about.links.termsAndConditions'),
          url: 'https://drive.google.com/file/d/1VHc87vj8QVCqwJ6o0KmvIu--8tcuk6QY/view?usp=sharing',
        },
        {
          text: t('footer.about.links.amlPolicy'),
          url: 'https://drive.google.com/file/d/1H1tNdGgSknvhCprh21eioX4twSzO1HCI/view?usp=sharing',
        },
        {
          text: t('footer.about.links.abAndAcPolicy'),
          url: 'https://drive.google.com/file/d/1lOy_tXg6pOpucf2Hcj2HgZa8drUHL60u/view?usp=sharing',
        },
        {
          text: t('footer.about.links.charter'),
          url: 'https://drive.google.com/file/d/1NGxFg3ddTJC0T92YE5WFZ7z-0LEIlLM2/view?usp=sharing',
        },
      ],
    },
    {
      title: t('footer.support.title'),
      links: [
        { text: t('footer.support.links.documents'), url: 'onboarding' },
        { text: t('footer.support.links.listing'), url: '/listing' },
      ],
    },
    {
      title: t('footer.contact.title'),
      links: [{ text: t('footer.contact.links.contacts'), url: '/contacts' }],
    },
  ];

  useEffect(() => {
    if (prevLocation && location.pathname === prevLocation.pathname) {
      const hash = window.location.hash;
      if (hash) {
        const element = document.querySelector(hash);
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
      } else {
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        });
      }
    }
    setPrevLocation(location);
  }, [location, prevLocation]);

  return (
    <footer className='footer'>
      <div className='container'>
        <div className='footer__container'>
          <div className='footer__left'>
            <h3 className='footer__title'>{t('footer.community.title')}</h3>
            <p className='footer__description'>{t('footer.community.description')}</p>
            <div className='footer__social'>
              {socialLinks.map((link, index) => (
                <a
                  key={index}
                  target='_blank'
                  rel='noopener noreferrer'
                  href={link.href}
                  className='footer__social-link'
                >
                  <img
                    src={link.icon}
                    alt={link.name}
                    className='footer__social-icon'
                  />
                </a>
              ))}
            </div>
          </div>
          <div className='footer__right'>
            {footerSections.map((section, index) => (
              <div key={index} className='footer__column'>
                <h4 className='footer__subtitle'>{section.title}</h4>
                <ul className='footer__list'>
                  {section.links.map((link, linkIndex) => (
                    <li key={linkIndex} className='footer__item'>
                      {link.url.startsWith('http') ? (
                        <a
                          href={link.url}
                          target='_blank'
                          rel='noopener noreferrer'
                          className='footer__link'
                        >
                          {link.text}
                        </a>
                      ) : (
                        <Link to={link.url} className='footer__link'>
                          {link.text}
                        </Link>
                      )}
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className='footer__bottom'>
        <p className='footer__copyright'>
          {t('footer.copyright', { year: new Date().getFullYear() })}
        </p>
      </div>
    </footer>
  );
};

export default Footer;
