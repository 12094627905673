import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';
import './styles.scss';
import { Link } from 'react-router-dom';

const News = () => {
	const { t } = useTranslation();

	const newsData = {
		exchangeNews: [
			{
				id: 1,
				date: '02.04.2025',
				title: t('exchangeNews.news.meeting'),
				href: '/assets/news/meeting.pdf',
			},
			{
				id: 2,
				date: '17.02.2025',
				title: t('exchangeNews.news.termsPublic'),
				href: 'https://drive.google.com/file/d/1ULKLYPVfIFTdrneO6_y0hv7obzgSrINO/view?usp=sharing',
			},
			{
				id: 3,
				date: '17.02.2025',
				title: t('exchangeNews.news.prospectusPreferred'),
				href: 'https://drive.google.com/file/d/1Eq-hW23wClUJ3S1aJ0N7Xis8zpatZP3A/view?usp=sharing',
			},
		],
		companyNews: [
			{
				id: 1,
				date: '29.01.2025',
				title: t('companyNews.news.royalQ4.title'),
				href: '/assets/news/royal_3.pdf',
			},

			{
				id: 2,
				date: t('companyNews.news.cybersecFact.date'),
				title: t('companyNews.news.cybersecFact.title'),
				href: '/assets/news/cybersec.pdf',
			},
			{
				id: 3,
				date: t('companyNews.news.lesopovalFact.date'),
				title: t('companyNews.news.lesopovalFact.title'),
				href: '/assets/news/lesopoval.pdf',
			},
		],
	};

	return (
		<div className='news-block'>
			<div className='container'>
				<motion.h2
					initial={{ opacity: 0, y: '-50px' }}
					whileInView={{ opacity: 1, y: 0 }}
					viewport={{ once: true }}
					transition={{ duration: 0.5 }}
					className='news-block__title title'
				>
					{t('news.title')}
				</motion.h2>
				<div className='news-block__columns'>
					<div className='news-block__column'>
						<motion.h3
							className='news-block__subtitle'
							initial={{ opacity: 0, x: '-50px' }}
							whileInView={{ opacity: 1, x: 0 }}
							viewport={{ once: true }}
							transition={{ duration: 0.5 }}
						>
							{t('news.exchange.title')}
						</motion.h3>
						<ul className='news-block__list'>
							{newsData.exchangeNews.map((news) => (
								<motion.li
									key={news.id}
									initial={{ opacity: 0, y: '50px' }}
									whileInView={{ opacity: 1, y: 0 }}
									viewport={{ once: true }}
									transition={{ duration: 0.5, delay: news.id * 0.2 }}
									className='news-block__item'
								>
									<span className='news-block__date'>{news.date}</span>
									<a target='_blank' rel='noreferrer' href={news.href}>
										{news.title}
									</a>
								</motion.li>
							))}
						</ul>
						<motion.div
							initial={{ opacity: 0, y: '50px' }}
							whileInView={{ opacity: 1, y: 0 }}
							viewport={{ once: true }}
							transition={{ duration: 0.5 }}
						>
							<Link to='exchange-news' className='news-block__link'>
								{t('news.viewAll')} →
							</Link>
						</motion.div>
					</div>

					<div className='news-block__column'>
						<motion.h3
							className='news-block__subtitle'
							initial={{ opacity: 0, x: '-50px' }}
							whileInView={{ opacity: 1, x: 0 }}
							viewport={{ once: true }}
							transition={{ duration: 0.5 }}
						>
							{t('news.company.title')}
						</motion.h3>
						<ul className='news-block__list'>
							{newsData.companyNews.map((news) => (
								<motion.li
									key={news.id}
									initial={{ opacity: 0, y: '50px' }}
									whileInView={{ opacity: 1, y: 0 }}
									viewport={{ once: true }}
									transition={{ duration: 0.5, delay: news.id * 0.2 }}
									className='news-block__item'
								>
									<span className='news-block__date'>{news.date}</span>
									<a target='_blank' rel='noreferrer' href={news.href}>
										{news.title}
									</a>
								</motion.li>
							))}
						</ul>
						<motion.div
							initial={{ opacity: 0, y: '50px' }}
							whileInView={{ opacity: 1, y: 0 }}
							viewport={{ once: true }}
							transition={{ duration: 0.5 }}
						>
							<Link to='company-news' className='news-block__link'>
								{t('news.viewAll')} →
							</Link>
						</motion.div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default News;
