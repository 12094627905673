import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation, Trans } from 'react-i18next';
import { motion } from 'framer-motion';
import AboutImg from '../../images/about/about.svg'
import './styles.scss';

const AboutUs = () => {
	const { t } = useTranslation();

	return (
		<section className='about_us'>
			<div className='container'>
				<div className='about_us__content'>
					<div className='about_us__info'>
						<motion.h1
							initial={{ opacity: 0, y: '-60px' }}
							animate={{ opacity: 1, y: 0 }}
							transition={{ duration: 0.5, delay: 0.2 }}
							className='about_us__title title'
						>
							<Trans i18nKey='about_us.title'>
								<span className='about_us__title_yellow'>Envoys Vision</span>{' '}
								Digital exchange
							</Trans>
						</motion.h1>
						<div className='about_us__block'>
							<motion.p
								initial={{ opacity: 0, y: '-60px' }}
								animate={{ opacity: 1, y: 0 }}
								transition={{ duration: 0.5, delay: 0.3 }}
								className='about_us__desc'
							>
								{t('about_us.description')}
							</motion.p>
							<motion.div
								initial={{ opacity: 0, y: '-60px' }}
								animate={{ opacity: 1, y: 0 }}
								transition={{ duration: 0.5, delay: 0.4 }}
								className='about_us__link-block'
							>
								<Link to='/documents' className='about_us__link'>
									{t('about_us.link')}
									<svg
										className='about_us__link-icon'
										width='10'
										height='10'
										viewBox='0 0 10 10'
										fill='none'
										xmlns='http://www.w3.org/2000/svg'
									>
										<path
											d='M8.00208 3.0605L1.54683 9.51575L0.486328 8.45525L6.94083 2H1.25208V0.5H9.50208V8.75H8.00208V3.0605Z'
											fill='current'
										></path>
									</svg>
								</Link>
							</motion.div>
						</div>
					</div>
					<div className='about_us__image-block'>
						<img src={AboutImg} alt="about" />
					</div>
				</div>
			</div>
		</section>
	);
};

export default AboutUs;
