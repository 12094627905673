import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import './styles.scss';

const Statement = () => {
	const { t, i18n } = useTranslation();

	const tabs = [t('statement.tabs.audited'), t('statement.tabs.unaudited')];

	const data = {
		[t('statement.tabs.audited')]: [
			{
				id: 1,
				name: t('statement.auditedReport2021_2023'),
				url: '/assets/listing/Фин_отчетность_аудированная_итоговая.pdf',
			},
		],
		[t('statement.tabs.unaudited')]: [
			{
				id: 1,
				name: t('statement.unauditedReportQ1_2024'),
				url: '/assets/listing/Приложение_2_1_1_кв_2024_отчет_ОАО_ЕВДЭ.pdf',
			},
			{
				id: 2,
				name: t('statement.unauditedReportQ2_2024'),
				url: '/assets/listing/Приложение_2_1_2_кв_2024_отчет_ОАО_ЕВДЭ.pdf',
			},
			{
				id: 3,
				name: t('statement.unauditedReportQ3_2024'),
				url: '/assets/listing/Приложение_2_3_квартал_2024.pdf',
			},
			{
				id: 4,
				name: t('statement.unauditedReportQ4_2024'),
				url: '/assets/listing/Приложение_2_4_квартал_2024.pdf',
			},
		],
	};

	const [activeTab, setActiveTab] = useState(t('statement.tabs.audited'));

	useEffect(() => {
		// Обновляем активную вкладку при изменении языка
		setActiveTab(t('statement.tabs.audited'));
	}, [i18n.language, t]);

	const renderContent = () => {
		return (
			<div className='listing__items'>
				{data[activeTab]?.map((item) => (
					<a
						key={item.id}
						href={item.url}
						target='_blank'
						rel='noopener noreferrer'
						className='listing__item listing__item--link'
					>
						{item.name}
					</a>
				))}
			</div>
		);
	};

	return (
		<div className='statement'>
			<div className='container'>
				<h1 className='statement__title'>{t('statement.title')}</h1>
				<div className='statement__tabs'>
					{tabs.map((tab) => (
						<button
							key={tab}
							className={`statement__tab ${
								activeTab === tab ? 'statement__tab--active' : ''
							}`}
							onClick={() => setActiveTab(tab)}
						>
							{tab}
						</button>
					))}
				</div>
				<div className='statement__info'>{renderContent()}</div>
			</div>
		</div>
	);
};

export default Statement;
