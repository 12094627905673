import React from 'react';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';
import './styles.scss';
import NewsImage1 from '../../../images/evde-news.png';
import NewsImage2 from '../../../images/evde-news2.png';
import NewsImage3 from '../../../images/evde-news3.png';
import NewsImage4 from '../../../images/evde-news4.png';
import NewsImage5 from '../../../images/evde-news5.png';
import NewsImage6 from '../../../images/evde-news6.png';
import NewsImage7 from '../../../images/evde-news7.png';

const ExchangeNews = () => {
	const { t } = useTranslation();

	const news = [
		{
			title: t('exchangeNews.news.generalMeeting2023'),
			desc: '',
			date: t('exchangeNews.news.generalMeeting2023Date'),
			image: NewsImage1,
			link: '/assets/news/эркинтооГОСА2023.pdf#page=11',
		},
		{
			title: t('exchangeNews.news.extraordinaryMeeting2023October'),
			desc: '',
			date: t('exchangeNews.news.extraordinaryMeeting2023OctoberDate'),
			image: NewsImage2,
			link: '/assets/news/Газета эркин тоо.pdf#page=10',
		},
		{
			title: t('exchangeNews.news.extraordinaryMeeting2023November'),
			desc: '',
			date: t('exchangeNews.news.extraordinaryMeeting2023NovemberDate'),
			image: NewsImage3,
			link: '/assets/news/эркин тоо газета.pdf#page=14',
		},
		{
			title: t('exchangeNews.news.generalMeeting2024'),
			desc: '',
			date: t('exchangeNews.news.generalMeeting2024Date'),
			image: NewsImage4,
			link: '/assets/news/Газета ПДФ 10 мая 2024, 10  стр.pdf#page=10',
		},
		{
			title: t('exchangeNews.news.prospectusPreferred'),
			desc: '',
			date: '17-02-2025',
			image: NewsImage5,
			link: 'https://drive.google.com/file/d/1Eq-hW23wClUJ3S1aJ0N7Xis8zpatZP3A/view?usp=sharing',
		},
		{
			title: t('exchangeNews.news.termsPublic'),
			desc: '',
			date: '17-02-2025',
			image: NewsImage6,
			link: 'https://drive.google.com/file/d/1ULKLYPVfIFTdrneO6_y0hv7obzgSrINO/view?usp=sharing',
		},
		{
			title: t('exchangeNews.news.meeting'),
			desc: '',
			date: '02-04-2025',
			image: NewsImage7,
			link: '/assets/news/meeting.pdf',
		},
	];

	return (
		<>
			<section className='news'>
				<div className='container'>
					<motion.h1
						initial={{ opacity: 0, y: '-60px' }}
						animate={{ opacity: 1, y: 0 }}
						transition={{ duration: 0.5 }}
						className='news__title title'
					>
						{t('exchangeNews.title')}
					</motion.h1>
					<div className='news__content'>
						{news.reverse().map((newsItem, index) => (
							<motion.div
								initial={{ scale: 0 }}
								animate={{ scale: 1 }}
								transition={{ duration: 0.4, delay: index * 0.2 }}
								key={index}
								className='news__col'
							>
								<a
									href={newsItem.link}
									target='_blank'
									className='news__card'
									rel='noopener noreferrer'
								>
									<div className='news__card-image--block'>
										<img
											src={newsItem.image}
											className='news__card-img'
											alt={t('exchangeNews.alt')}
										/>
									</div>
									<span className='news__card-date'>{newsItem.date}</span>
									<h3 className='news__card-title'>{newsItem.title}</h3>
								</a>
							</motion.div>
						))}
					</div>
				</div>
			</section>
		</>
	);
};

export default ExchangeNews;
